path {
  stroke-width: 10;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  -webkit-animation: dash 2s linear forwards 0s;
  -o-animation: dash 2s linear forwards 0s;
  -moz-animation: dash 2s linear forwards 0s;
  animation: dash 2s linear forwards 0s;
}

