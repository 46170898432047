.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: thin;
  font-family: "Montserrat";
}

.footer-div {
  margin-top: 2rem;
}
