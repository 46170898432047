#topButton {
  visibility: hidden;
  position: fixed;
  bottom: 1.2rem;
  right: 1.5rem;
  cursor: pointer;
  padding: 8px 10px 8px 10px;
  outline: none;
  border-radius: 10rem;
  font-size: 1rem;
}
